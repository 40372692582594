import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { base_url } from "../utils/baseUrl";
import { toast } from "react-toastify";
import PreviewImage from "../components/PreviewImage";
import JoditEditor from "jodit-react";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  image: yup.mixed().required("Image is required"),
  description: yup.string().required("Description is required"),
});

const generateSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+/g, "-") // Replace spaces and special characters with hyphens
    .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
};

const AddBlog = () => {
  const editor = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditing = Boolean(id);
  const [image, setImage] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      image: null,
      description: "",
      meta: "",
    },

    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values) => {
      const { name, image, description, meta } = values;
      let profilephoto = image;
      const slug = generateSlug(name); // Generate the slug from the blog name

      try {
        if (image && typeof image !== "string") {
          const formData = new FormData();
          formData.append("avatars", image);
          const response = await axios.post(`${base_url}blog/image`, formData);
          profilephoto = response.data.data[0].url;
        }

        const params = { name: slug, image: profilephoto, description, meta }; // Use the slug for the name

        if (isEditing) {
          await axios.put(`${base_url}blog/update/${id}`, params);
          toast.success("Blog Updated Successfully!");
        } else {
          await axios.post(`${base_url}blog/create`, params);
          toast.success("Blog Added Successfully!");
        }
        navigate("/admin/blogs");
      } catch (error) {
        //console.error("Error submitting blog", error);
        toast.error("Error submitting blog");
      }
    },
  });

  useEffect(() => {
    if (isEditing && id) {
      const apiUrl = `${base_url}blog/get/${id}`;
      // console.log("API URL:", apiUrl); // Add this line
      axios
        .get(apiUrl)
        .then((res) => {
          // console.log("API response:", res);
          if (res.data && res.data.data) {
            const { name, image, description, meta } = res.data.data;
            formik.setFieldValue("name", name);
            formik.setFieldValue("meta", meta);
            formik.setFieldValue("description", description);
            formik.setFieldValue("image", image);
          }
        })
        .catch((err) => {
          //  console.error("Error fetching blog data", err); // Existing logging line
          toast.error("Failed to fetch blog data");
        });
    }
  }, [id, isEditing]);

  return (
    <div className="container-fluid">
      <div className="page-header pb-4">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col-sm-10">
            <div className="page-header-left">
              <h3>{isEditing ? "Edit Blog" : "Add Blog"}</h3>
              <small>Welcome to admin panel</small>
            </div>
          </div>
          <div className="col-sm-2">
            <ol className="breadcrumb pull-right">
              <li className="breadcrumb-item">
                <a href="index.html">
                  <i className="fa fa-home" />
                </a>
              </li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header pb-0">
              <h5>{isEditing ? "Edit Blog Details" : "Add Blog Details"}</h5>
            </div>
            <div className="card-body admin-form">
              <form
                className="row g-3"
                onSubmit={(event) => {
                  event.preventDefault(); // Prevent default form submission
                  formik.handleSubmit(event); // Call Formik's submit handler

                  // console.log("Form submitted");
                }}
              >
                <div className="form-group col-sm-12">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Title"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="error">{formik.errors.name}</div>
                  ) : null}
                </div>
                <div className="form-group col-sm-12">
                  <label>Meta Description</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Meta Description"
                    name="meta"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.meta}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="error">{formik.errors.name}</div>
                  ) : null}
                </div>

                <div className="form-group col-sm-12">
                  <label>Description</label>
                  <JoditEditor
                    ref={editor}
                    value={formik.values.description}
                    onChange={(newContent) =>
                      formik.setFieldValue("description", newContent)
                    }
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <div className="error">{formik.errors.description}</div>
                  ) : null}
                </div>

                <div className="dropzone-admin mb-0 mt-3">
                  <label>Media</label>
                  <div className="dropzone dz-clickable text-center">
                    <div className="dz-message needsclick">
                      <i
                        className="fas fa-cloud-upload-alt"
                        style={{ fontSize: "30px" }}
                      />
                      <div>
                        <input
                          type="file"
                          className="pb-4 w-100"
                          name="image"
                          onChange={(e) =>
                            formik.setFieldValue("image", e.target.files[0])
                          }
                        />
                      </div>
                      <h6>Drop files here or click to upload.</h6>
                    </div>
                  </div>
                  {formik.touched.image && formik.errors.image ? (
                    <div className="error">{formik.errors.image}</div>
                  ) : null}
                </div>

                {formik.values.image &&
                  typeof formik.values.image === "string" && (
                    <img
                      src={formik.values.image}
                      alt="Blog"
                      style={{ width: "100px", height: "100px" }}
                    />
                  )}

                {formik.values.image &&
                  typeof formik.values.image !== "string" && (
                    <PreviewImage file={formik.values.image} />
                  )}

                <div className="form-btn mt-4">
                  <button
                    type="submit"
                    className="btn btn-pill px-5 btn-gradient color-4"
                  >
                    {isEditing ? "Update" : "Submit"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-pill px-5 ms-3 btn-dashed color-4"
                    onClick={() => navigate("/admin/blogs")}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;
