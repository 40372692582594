import React, { useEffect, useState } from "react";
import { Input, Modal, Table, Select } from "antd";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import CustomModal from "../components/CustomModal";
import { base_url } from "../utils/baseUrl";

const { Option } = Select;

const PropertyList = () => {
  const [searchText, setSearchText] = useState("");
  const [toggle, setToggle] = useState({ id: "", status: "" });
  const [open, setOpen] = useState(false);
  const [blogId, setblogId] = useState("");
  const [listing, setListing] = useState([]);
  const navigate = useNavigate();

  const columns = [
    { title: "SNo", dataIndex: "key" },
    {
      title: "Title",
      dataIndex: "title",
      filteredValue: [searchText],
      onFilter: (value, record) =>
        String(record.title).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()) ||
        String(record.key) === value,
    },
    { title: "Image", dataIndex: "image" },
    { title: "ID", dataIndex: "id" },
    { title: "Price", dataIndex: "price" },
    { title: "Beds", dataIndex: "beds" },
    { title: "Baths", dataIndex: "baths" },
    { title: "Email", dataIndex: "email" },
    {
      title: "Status",
      dataIndex: "Status",
      render: (text, record) => (
        <Select
          defaultValue={record.Status}
          onChange={(value) => updateStatus(record.id, value)}
          style={{ width: 120 }}
        >
          <Option value="Active">Active</Option>
          <Option value="Inactive">Inactive</Option>
          <Option value="Pending">Pending</Option>
          <Option value="Rejected">Rejected</Option>
        </Select>
      ),
    },
    { title: "Updated_At", dataIndex: "updatedAt" },
    { title: "Featured", dataIndex: "featured" },
    { title: "Action", dataIndex: "action" },
  ];

  const showModal = (id) => {
    setOpen(true);
    setblogId(id);
  };

  const hideModal = () => setOpen(false);

  const fetchProperties = () => {
    axios
      .get(`${base_url}property/get`)
      .then((res) => {
        console.log("Fetched Properties:", res.data.data);
        setListing(res.data.data);
      })
      .catch((err) => console.error("Error fetching properties:", err));
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  const deleteProperty = (id) => {
    axios
      .delete(`${base_url}property/delete/${id}`)
      .then((res) => {
        if (res.data.status === "ok") {
          toast.success("Property deleted successfully!");
          fetchProperties();
        }
      })
      .catch((err) => console.error(err));
    setOpen(false);
  };

  const toggleFeatured = (id, status) => {
    const params = { featured: status };
    axios
      .put(`${base_url}property/update/${id}`, params)
      .then((res) => {
        if (res.data.status === "ok") {
          toast.success(`Featured ${status ? "enabled" : "disabled"}`);
          fetchProperties();
        }
      })
      .catch((err) => console.error(err));
  };

  const updateStatus = (id, status) => {
    axios
      .put(`${base_url}property/update/${id}`, { Status: status })
      .then((res) => {
        if (res.data.status === "ok") {
          toast.success("Status updated successfully!");
          fetchProperties();
        }
      })
      .catch((err) => console.error("Error updating status:", err));
  };

  const data = listing.map((item, index) => ({
    key: index + 1,
    title: item.title,
    image: (
      <img
        src={item.image[0]}
        alt=""
        style={{ width: "60px", height: "60px", borderRadius: "50%" }}
      />
    ),
    id: item.id,
    price: item.price,
    beds: item.beds,
    baths: item.baths,
    email: item.email,
    Status: item.Status,
    updatedAt: item.updatedAt,
    featured: (
      <label className="switch">
        <input
          type="checkbox"
          checked={item.featured}
          onChange={() => toggleFeatured(item.id, !item.featured)}
        />
        <span className="slider round"></span>
      </label>
    ),
    action: (
      <>
        <button
          className="ms-3 fs-3 text-primary bg-transparent border-0"
          onClick={() => navigate(`/admin/add_property/${item.id}`)}
        >
          <BiEdit />
        </button>
        <button
          className="ms-3 fs-3 text-danger bg-transparent border-0"
          onClick={() => showModal(item.id)}
        >
          <AiFillDelete />
        </button>
      </>
    ),
  }));

  return (
    <div>
      <div className="page-header pb-4">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col-sm-10">
            <div className="page-header-left">
              <h3>
                Property List <small>Welcome to the admin panel</small>
              </h3>
            </div>
          </div>
          <div className="col-sm-2">
            <ol className="breadcrumb pull-right">
              <li className="breadcrumb-item">
                <a href="index.html">
                  <i className="fa fa-home" />
                </a>
              </li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </div>
        </div>
      </div>
      <Input.Search
        placeholder="Enter search here.."
        onSearch={(value) => setSearchText(value)}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div className="pt-3">
        <Table columns={columns} dataSource={data} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => deleteProperty(blogId)}
        title="Are you sure you want to delete this property?"
      />
      <div style={{ position: "absolute", bottom: 60, right: 100 }}>
        <div
          className="text-center"
          style={{
            width: "80px",
            height: "80px",
            lineHeight: "75px",
            backgroundColor: "#4DB749",
            borderRadius: "50%",
          }}
        >
          <Link
            to="/admin/add_property"
            className="text-center text-white fs-1"
          >
            +
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PropertyList;
