import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { base_url } from "../utils/baseUrl";
import { toast } from "react-toastify";
import PreviewImage from "../components/PreviewImage";

let schema = yup.object().shape({
  title: yup.string().required("title is Required"),
  city: yup.string().required("City is Required"),
  location: yup.string().required("Location is Required"),
  size: yup.string().required("Size is Required"),
  beds: yup.string().required("Beds is Required"),
  baths: yup.string().required("Baths is Required"),
  image: yup.mixed().required("image is Required"),
  price: yup.mixed().required("Price is Required"),
  phone: yup.mixed().required("Phone is Required"),
  purpose: yup.mixed().required("Purpose is Required"),
  description: yup.string().required("description is Required"),
});

const AddProperty = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (id) {
      axios.get(`${base_url}property/get/${id}`).then((response) => {
        const data = response.data.data;
        formik.setValues({
          image: data.image,
          video: data.video,
          purpose: data.purpose,
          property: data.property,
          type: data.type,
          feature: data.feature,
          city: data.city,
          location: data.location,
          size: data.size,
          email: data.email,
          price: data.price,
          beds: data.beds,
          baths: data.baths,
          title: data.title,
          description: data.description,
          phone: data.phone,
          landline: data.landline,
        });
        setImages(data.image); // If images are part of the property data
      });
    }
  }, [id]);

  const imageHandler = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage && selectedImage.type.startsWith("image/")) {
      console.log("Selected Image:", selectedImage);
      setImages([...images, selectedImage]);
    } else {
      console.error("Please select a valid image file.");
    }
  };

  const handleRemoveItem = (id) => {
    setImages(images.filter((item) => item !== id));
  };

  const formik = useFormik({
    initialValues: {
      image: [],
      video: "",
      purpose: "",
      property: "",
      type: "",
      feature: "",
      city: "",
      location: "",
      size: "",
      email: "",
      price: "",
      beds: "",
      baths: "",
      title: "",
      description: "",
      phone: "",
      landline: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const {
        image,
        video,
        purpose,
        property,
        type,
        feature,
        city,
        location,
        size,
        email,
        price,
        beds,
        baths,
        title,
        description,
        phone,
        landline,
      } = formik.values;
      console.log(image);

      let profilephoto = "";

      try {
        let param = new FormData();
        param.append("file", image);
        images.map((ite) => {
          param.append("images", ite);
        });
        profilephoto = await axios.post(
          `http://localhost:3005/api/property/image`,
          param
        );

        console.log(profilephoto);
      } catch (error) {
        console.log(error);
      }

      console.log(profilephoto, "sdhfdfssssssssssssss");

      const params = {
        image: profilephoto?.data?.data,
        video: video,
        purpose: purpose,
        property: property,
        type: type,
        feature: feature,
        city: city,
        location: location,
        size: size,
        email: email,
        price: price,
        beds: beds,
        baths: baths,
        phone: phone,
        landline: landline,
        title: title,
        description: description,
      };

      console.log("data---", params);
      let response;
      if (id) {
        response = await axios.put(`${base_url}property/update/${id}`, params);
      } else {
        response = await axios.post(`${base_url}property/create`, params);
      }

      if (response.data.status === "ok") {
        toast.success(
          id ? "Property Updated Successfully!" : "Property Added Successfully!"
        );
        navigate("/admin/property_list");
      }
      console.log("response :::", response);
    },
  });

  return (
    <>
      <div className="container-fluid">
        <div className="page-header pb-4">
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-sm-10">
              <div className="page-header-left">
                <h3>
                  {id ? "Edit Property" : "Add Property"}
                  <small>Welcome to admin panel</small>
                </h3>
              </div>
            </div>
            <div className="col-sm-2">
              <ol className="breadcrumb pull-right">
                <li className="breadcrumb-item">
                  <a href="index.html">
                    <i className="fa fa-home" />
                  </a>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header pb-0">
                <h5>{id ? "Edit Property Details" : "Add Property Details"}</h5>
              </div>
              <div className="card-body admin-form">
                <form className="row g-3" onSubmit={formik.handleSubmit}>
                  <div className="form-group col-sm-6">
                    <label>Title</label>
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      placeholder="Enter your title"
                      onChange={formik.handleChange("title")}
                      onBlur={formik.handleBlur("title")}
                      value={formik.values.title}
                    />
                    <div className="error">
                      {formik.touched.title && formik.errors.title}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Purpose</label>
                    <select
                      className="form-select"
                      onChange={formik.handleChange("purpose")}
                      onBlur={formik.handleBlur("purpose")}
                      value={formik.values.purpose}
                      aria-label="Default select example"
                    >
                      <option selected>Select your purpose</option>
                      <option value="rent">Rent</option>
                      <option value="sell">Sale</option>
                    </select>
                    <div className="error">
                      {formik.touched.purpose && formik.errors.purpose}
                    </div>
                  </div>

                  <div className="form-group col-sm-6">
                    <label>Property</label>
                    <input
                      type="text"
                      name="property"
                      className="form-control"
                      placeholder="Enter your property"
                      onChange={formik.handleChange("property")}
                      onBlur={formik.handleBlur("property")}
                      value={formik.values.property}
                    />
                    <div className="error">
                      {formik.touched.property && formik.errors.property}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Type</label>
                    <input
                      type="text"
                      name="type"
                      className="form-control"
                      placeholder="Enter your type"
                      onChange={formik.handleChange("type")}
                      onBlur={formik.handleBlur("type")}
                      value={formik.values.type}
                    />
                    <div className="error">
                      {formik.touched.type && formik.errors.type}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Feature</label>
                    <input
                      type="text"
                      name="feature"
                      className="form-control"
                      placeholder="Enter your feature"
                      onChange={formik.handleChange("feature")}
                      onBlur={formik.handleBlur("feature")}
                      value={formik.values.feature}
                    />
                    <div className="error">
                      {formik.touched.feature && formik.errors.feature}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>City</label>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      placeholder="Enter your city"
                      onChange={formik.handleChange("city")}
                      onBlur={formik.handleBlur("city")}
                      value={formik.values.city}
                    />
                    <div className="error">
                      {formik.touched.city && formik.errors.city}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Location</label>
                    <input
                      type="text"
                      name="location"
                      className="form-control"
                      placeholder="Enter your location"
                      onChange={formik.handleChange("location")}
                      onBlur={formik.handleBlur("location")}
                      value={formik.values.location}
                    />
                    <div className="error">
                      {formik.touched.location && formik.errors.location}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Size</label>
                    <input
                      type="text"
                      name="size"
                      className="form-control"
                      placeholder="Enter your size"
                      onChange={formik.handleChange("size")}
                      onBlur={formik.handleBlur("size")}
                      value={formik.values.size}
                    />
                    <div className="error">
                      {formik.touched.size && formik.errors.size}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter your email"
                      onChange={formik.handleChange("email")}
                      onBlur={formik.handleBlur("email")}
                      value={formik.values.email}
                    />
                    <div className="error">
                      {formik.touched.email && formik.errors.email}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Price</label>
                    <input
                      type="number"
                      name="price"
                      className="form-control"
                      placeholder="Enter your price"
                      onChange={formik.handleChange("price")}
                      onBlur={formik.handleBlur("price")}
                      value={formik.values.price}
                    />
                    <div className="error">
                      {formik.touched.price && formik.errors.price}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Beds</label>
                    <input
                      type="number"
                      name="beds"
                      className="form-control"
                      placeholder="Enter your beds"
                      onChange={formik.handleChange("beds")}
                      onBlur={formik.handleBlur("beds")}
                      value={formik.values.beds}
                    />
                    <div className="error">
                      {formik.touched.beds && formik.errors.beds}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Baths</label>
                    <input
                      type="number"
                      name="baths"
                      className="form-control"
                      placeholder="Enter your baths"
                      onChange={formik.handleChange("baths")}
                      onBlur={formik.handleBlur("baths")}
                      value={formik.values.baths}
                    />
                    <div className="error">
                      {formik.touched.baths && formik.errors.baths}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Phone</label>
                    <input
                      type="tel"
                      name="phone"
                      className="form-control"
                      placeholder="Enter your phone"
                      onChange={formik.handleChange("phone")}
                      onBlur={formik.handleBlur("phone")}
                      value={formik.values.phone}
                    />
                    <div className="error">
                      {formik.touched.phone && formik.errors.phone}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Landline</label>
                    <input
                      type="tel"
                      name="landline"
                      className="form-control"
                      placeholder="Enter your landline"
                      onChange={formik.handleChange("landline")}
                      onBlur={formik.handleBlur("landline")}
                      value={formik.values.landline}
                    />
                    <div className="error">
                      {formik.touched.landline && formik.errors.landline}
                    </div>
                  </div>

                  <div className="form-group col-sm-6">
                    <label>Description</label>
                    <textarea
                      type="text"
                      name="description"
                      className="form-control"
                      placeholder="Enter description"
                      onChange={formik.handleChange("description")}
                      onBlur={formik.handleBlur("description")}
                      value={formik.values.description}
                    />
                    <div className="error">
                      {formik.touched.description && formik.errors.description}
                    </div>
                  </div>

                  <div className="form-group col-sm-6">
                    <label>Image</label>
                    <input
                      type="file"
                      name="image"
                      className="form-control"
                      placeholder="Upload your image"
                      onChange={imageHandler}
                      onBlur={formik.handleBlur("image")}
                    />
                    <div className="error">
                      {formik.touched.image && formik.errors.image}
                    </div>
                  </div>

                  <div className="form-group col-sm-6">
                    <label>Video</label>
                    <input
                      type="file"
                      name="video"
                      className="form-control"
                      placeholder="Upload your video"
                      onChange={formik.handleChange("video")}
                      onBlur={formik.handleBlur("video")}
                      value={formik.values.video}
                    />
                    <div className="error">
                      {formik.touched.video && formik.errors.video}
                    </div>
                  </div>

                  <div className="form-group col-sm-6">
                    <PreviewImage
                      fileData={images}
                      removeItem={handleRemoveItem}
                    />
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        {id ? "Update Property" : "Add Property"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProperty;
