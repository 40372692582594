import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

    // Check if the credentials match the required admin credentials
    if (email === "admin" && password === "rootadmin1199") {
      navigate("/admin"); // Redirects to the admin page on successful login
    } else {
      setErrorMessage("Invalid credentials, please try again.");
    }
  };

  return (
    <div className="authentication-box">
      <div className="container">
        <div className="row log-in">
          <div className="col-lg-5 col-md-6 col-sm-12 form-login">
            <div className="card">
              <div className="card-body">
                <h2 className="title-3 text-start">Log in</h2>
                <form autoComplete="off" onSubmit={handleLogin}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="off"
                      maxLength={20}
                    />
                  </div>
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <button type="submit" className="btn btn-pill px-5 btn-gradient color-4">
                    Log in
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-6 col-sm-12 auth-img bg-size">
            {/* Background image could be placed here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
